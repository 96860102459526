var isPage = "";
let Block = {
	top: 0,
	show: 0
}
let blocks = [];
let Animate = {
	top: 0,
	show: 0
}
let animations = [];
var common = {
	init: ()=>{
		var resizeFlag;
		var resizeTimer = null;
		var $body = $("body");
		project.init();
		common.resize();
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		$(document).off("touchmove scroll").on("touchmove scroll", function(){
			common.scrl();
		});

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
	},
	nav: ()=>{
		//share
		$(".sharewith li a").off().on("click", function(){
			$this = $(this);
			var idx = $(".sharewith li a").index(this);
			var url = $(this).attr("data-share");
			shareTxt(idx,url);
		});
		function shareTxt(num,shareUrl){
			var domain = "http://" + document.domain;
			var url 	= domain + shareUrl;
			if(num === 0){
				//Twitter
				var txt = $this.attr("data-text");
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(url) + "&amp;text=" + encodeURIComponent(txt),
					'twittersharedialog',
					'width=626,height=436');
			}else if(num === 1){
				//Facebook
				window.open(
					'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
					'facebooksharedialog',
					'width=626,height=436');
			}else{
				//LINE
				var txt = $this.attr("data-text") + "\n" + url;
				window.open(
					'http://line.me/R/msg/text/?' + encodeURIComponent(txt),
					'linesharedialog',
					'width=626,height=436');
			}
		}
	},
	resize: ()=>{
		var winW = window.innerWidth;
		var winH = window.innerHeight;
		var $body = $("body");

		animations = [];
		$(".a").each(function(){
			let _a = object(Animate);
			_a.top = Math.floor( $(this).offset().top );
			_a.show = Math.floor( _a.top + window.innerHeight/3 );
			animations.push(_a);
		});
		blocks = [];
		$(".block").each(function(){
			let _b = object(Block);
			_b.top = Math.floor( $(this).offset().top );
			_b.show = Math.floor( _b.top + window.innerHeight/3 );
			blocks.push(_b);
		});
		console.log(blocks, animations);
		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	scrollToPosition: (id,callback) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : "0px"
		},{
			"duration" 	: 1000,
			"easing" 	: ease,
			"complete"	: function(){
				if(flag){
					flag = false;
				}
			}
		});
	},
	scrl: () =>{
		var topD = $(document).scrollTop(),
			btmD = topD + $(window).height();
		if( topD >= $("#projects").offset().top ){
			if( !$("#bg").hasClass("active") ){
				let n = Math.floor( Math.random()*4);
				$("#bg").addClass("b" + n);
				$("#bg").addClass("active");
			}
		}else{
			if( $("#bg").hasClass("active") ){
				$("#bg").removeClass();
			}
		}
		let alen = animations.length;
		for( var a = 0; a < alen; a++ ){
			if(a === alen - 1){
				if(btmD >= animations[a].show){
					common.activeAnimate(a);
				}
			}else{
				if(btmD >= animations[a].show){
					common.activeAnimate(a);
				}
			}
		}
		if(btmD > $(document).height() - 100){
			common.activeAnimate(animations.length-2);
			common.activeAnimate(animations.length-1);
		}
	},
	activeAnimate: function(a){
		let $a = $(".a").eq(a);
		if( !$a.hasClass("animate") ){
			$a.addClass("animate");
			setTimeout(function(){
				$a.addClass("activeAnimate");
			},600);
		}
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	}
}
document.addEventListener('DOMContentLoaded', function () {
	isPage = $("body").attr("id");
	common.init();
});