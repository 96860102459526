let project = {
	url: "/assets/json/projects.json",
	clientURL: "/assets/json/clients.json",
	archiveURL: "/assets/json/archives.json",
	clients: [],
	init: function(){
		load.getJson(this.url, {}).done(function(json){
			let data = json.projects;
			let projectLen = json.show;
			//console.log(data);
			let clients = [];
			let HTML = "";
			for(var a = 0; a < projectLen; a++){
				let d = data[a];
				let tag = d.tag;
				let tagHTML = '';
				for(let b = 0; b < tag.length; b++){
					tagHTML += '<li>' + tag[b] + '</li>';
				}
				if(d.link != ""){
					HTML += `<li class="project"><a href="${d.link}" title="${d.title}" target="_blank" rel="noopener">
						<div class="img"><figure style="background-image: url(/assets/images/project/${d.image})"></figure></div>
						<div class="txt">
							<h4>${d.title}</h4>
							<ul>${tagHTML}</ul>
						</div></a></li>`;
				}else{
					HTML += `<li class="project">
						<div class="img"><figure style="background-image: url(/assets/images/project/${d.image})"></figure></div>
						<div class="txt">
							<h4>${d.title}</h4>
							<ul>${tagHTML}</ul>
						</div></li>`;
				}
			}

			$("#projects ul").html(HTML);

			load.getJson(project.clientURL, {}).done(function(json){
			//	console.log(json);
				let client = json.clients;
				let clientHTML = '';
				for(var i = 0; i < client.length; i++){
					let c = client[i];
					clientHTML += `<li>${c.name}</li>`;
				}
				clientHTML += '<li>etc</li>';
				$("#list_client").html(clientHTML);
			});
			load.getJson(project.archiveURL, {}).done(function(json){
			//	console.log(json);
				let archives = json.archives;
				
				for(let i = 0; i < archives.length; i++){
					let a = archives[i];
					let archiveHTML = '';
					archiveHTML += '<div class="archive"><h5>' + a.clientName + ' Project</h5><ul>';
					for(let j = 0; j < a.projects.length; j++){
						archiveHTML += '<li>' + a.projects[j].name + '</li>'
					}
					archiveHTML += '</ul></div>';
					$("#archives .content").append(archiveHTML);
				}
				common.resize();
				setTimeout(function(){
					common.resize();
					common.activeAnimate(0);
				}, 600);
				$("body").removeClass("disable");
			});
			/*
			console.log(data);
			for(let i = 0; i < data.length; i++){
				let d = data[i];
				clients.push(d.client);
			}
			
			project.clients = _.union(clients).sort(sortFunc);
			let clientHTML = '';
			let archiveBlockHTML = '';
			for(var c = 0; c < project.clients.length; c++){
				let client = project.clients[c];
				clientHTML += `<li>${client}</li>`;
				archiveBlockHTML += '<div class="archive"><h5>' + client + ' Project</h5><ul></ul></div>'
			}
			$("#list_client").html(clientHTML);
			$("#archives .content").append(archiveBlockHTML);
			console.log(project.clients);

			//プロジェクトで使用したデータは削除
			data.splice(0, projectLen);

			for(var f = 0; f < project.clients.length; f++){
				let client = project.clients[f];
				let archiveHTML = '';
				_.each(data, (item) =>{
					if(item.client === client){
						archiveHTML += `<li>${item.title}</li>`;
					}
				})
				$("#archives .archive").eq(f+1).find("ul").html(archiveHTML);
			}
			*/
		});
	}
}
function sortFunc(a, b){
	a = a.toString().toLowerCase();
	b = b.toString().toLowerCase();
	if(a < b) return -1;
	else if(a > b) return 1;
	return 0;
}